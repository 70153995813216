export default {
  //invoices
  SET_INVOICES(state, invoices) {
    state.invoices = invoices
  },
  //setInvoices for grid
  SET_INVOICES_GRID(state, { invoices, pagesCount, totalCountData }) {
    state.invoices = invoices
    state.pagesCount = pagesCount
    state.totalCountData = totalCountData
  },
  SET_INVOICE(state, invoice) {
    state.invoice = invoice
  },
  ADD_INVOICE(state, invoice) {
    state.invoices.push(invoice)
  },
  UPDATE_INVOICE(state, invoice) {
    const index = state.invoices.findIndex((i) => i.id === invoice.id)
    state.invoices.splice(index, 1, invoice)
  },
  UPDATE_INVOICE_BY_ID(state, id) {
    const index = state.invoices.findIndex((i) => i.id === id)
    const invoice = state.invoices.filter((i) => i.id === id)[0]
    invoice.invoiceStatus = 2
    state.invoices.splice(index, 1, invoice)
  },
  DELETE_INVOICE(state, id) {
    const index = state.invoices.findIndex((i) => i.id === id)
    state.invoices.splice(index, 1)
  },
  // change invoice status
  CHANGE_INVOICE_STATUS(state, id) {
    const index = state.invoices.findIndex((i) => i.id === id)
  },
  setInvoiceItems(state, items) {
    state.invoiceItems = items.map(({ id, ...rest }) => rest)
  },
  setNoticeItems(state, items) {
    state.noticeItems = items.map(({ id, ...rest }) => rest)
  },
  addInvoiceItem(state, item) {
    state.invoiceItems.push(item)
  },
  updateInvoiceItem(state, updatedItem) {
    const index = state.invoiceItems.findIndex((item) => item.id === updatedItem.id)
    if (index !== -1) {
      state.invoiceItems.splice(index, 1, updatedItem)
    }
  },
  removeInvoiceItem(state, itemId) {
    state.invoiceItems = state.invoiceItems.filter((item) => item.id !== itemId)
  },
  moveInvoiceItem(state, { index, direction }) {
    const item = state.invoiceItems.splice(index, 1)[0]
    const newIndex = direction === 'up' ? index - 1 : index + 1
    state.invoiceItems.splice(newIndex, 0, item)
  },
  SET_INVOICE_ITEMS(state, invoiceItems) {
    state.invoiceItems = invoiceItems
  },
  SET_INVOICE_ITEM(state, invoiceItem) {
    state.invoiceItem = invoiceItem
  },
  ADD_INVOICE_ITEM(state, invoiceItem) {
    state.invoiceItems.push(invoiceItem)
  },
  UPDATE_INVOICE_ITEM(state, invoiceItem) {
    const index = state.invoiceItems.findIndex((i) => i.id === invoiceItem.id)
    state.invoiceItems.splice(index, 1, invoiceItem)
  },
  DELETE_INVOICE_ITEM(state, id) {
    const index = state.invoiceItems.findIndex((i) => i.id === id)
    state.invoiceItems.splice(index, 1)
  },
  //taxes
  SET_TAXES(state, taxes) {
    state.taxes = taxes
  },
  //companies
  SET_COMPANIES(state, companies) {
    state.companies = companies
  }
}
