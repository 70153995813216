import axios from '@axios'
import api from '../../../services/api'

export default {
  async GetAgents(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Agent/GetAgents')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  //update costbody
  
  async GetAgentPlaces(context, placeId) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Admins/GetAgentServicePlaces?id=' + placeId)
        .then(async function (response) {
          if (response.status == 200) {
            const data = response.data
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddAgent(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Agent/CreateAgent', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetAgent(context, agentId) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Agent/GetAgent?id=' + agentId)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetAgentWithoutId(context) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Agent/GetAgentWithoutId')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateAgent(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Agent/UpdateAgent?id=' + obj.id, obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteAgent(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Agent/DeleteAgent?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async FilterCoupons(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Coupons/GetCoupons', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async FilterExpenses(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Expenses/GetExpenses', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  async AddCostToAgent(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Agent/AddCostToAgent', payload)
        .then(async function (response) {
          if (response.status == 204) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async WithDrawCost(context, id) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Agent/WithDrawCostFromAgent?id=' + id, {})
        .then(async function (response) {
          if (response.status == 204) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async ReviewCoupon(context, id) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Coupons/ReviewCoupon?couponId=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async ReviewExpense(context, id) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Expenses/ReviewExpense?expenseId=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetAgentCustodyHistory(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Agent/GetAgentCustodyHistory', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}
