export default {
  SET_BILLOFLANDINGS_GRID(state, { billOfLandings, pagesCount, totalCountData }) {
    state.billOfLandings = billOfLandings;
    state.pagesCount = pagesCount;
    state.totalCountData = totalCountData;
  },
  SET_BILLOFLANDINGS(state, billOfLandings) {
    state.billOfLandings = billOfLandings
  },
  SET_BILLOFLANDING(state, billOfLanding) {
    state.billOfLanding = billOfLanding
  },
  ADD_BILLOFLANDING(state, billOfLanding) {
    state.billOfLandings.push(billOfLanding)
  },
  UPDATE_BILLOFLANDING(state, billOfLanding) {
    const index = state.billOfLandings.findIndex((i) => i.id === billOfLanding.id)
    state.billOfLandings.splice(index, 1, billOfLanding)
  },
  DELETE_BILLOFLANDING(state, id) {
    const index = state.billOfLandings.findIndex((i) => i.id === id)
    state.billOfLandings.splice(index, 1)
  }
}
