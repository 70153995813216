import axios from "axios";
import { Decrypt } from "../store/modules/Auth/encrypt";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_LINK}`,
});

api.interceptors.request.use((config) => {
  let token = null;
  if (localStorage.getItem("userInfo")) {
    token = Decrypt();
  }

  token ? (config.headers.Authorization = `Bearer ${token}`) : null;
  return config;
});
api.interceptors.response.use(response => {
  const transformResponseData = (data) => {
    if (Array.isArray(data)) {
      return data.map(item => transformResponseData(item));
    } else if (typeof data === 'object' && data !== null) {
      Object.keys(data).forEach(key => {
        if ((key.includes('date') || key.includes('Date')) && typeof data[key] === 'string' && isIsoDateString(data[key])) {
          data[key] = formatIsoDateString(data[key]);
        } else if (typeof data[key] === 'object') {
          data[key] = transformResponseData(data[key]);
        }
      });
    }
    return data;
  };

  // Check if the result property exists in response.data
  if (response.data && response.data.result) {
    response.data.result = transformResponseData(response.data.result);
  }
  return response;
});
function formatIsoDateString(isoString) {
  return isoString.replace('T', ' ').slice(0, 19);
}

function isIsoDateString(str) {
  // Simple check for ISO date format
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(str);
}


export default api;
