export default{
    companies:[
    ],
    company:{},
    payments:[
       
    ],
    bills:[
       
    ],
    billDetails:[
       
    ],
 
  
  
}