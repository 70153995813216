export default {
  invoices: [],
  invoice: {},
  pageTotal: 0,
  totalCountData: 0,

  invoiceItems: [],
  invoiceItem: {},

  noticeItems: [],
  noticeItem: {},

  taxes: [],
  companies: []
}
