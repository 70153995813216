var CryptoJS = require("crypto-js");
export const Encrypt =(message)=>{
    var message = message 
    var key= process.env.VUE_APP_SECRET_KEY;
    var encrypted = CryptoJS.AES.encrypt(message, key);
    return encrypted
}
export const Decrypt =()=>{
    var message = localStorage.getItem('accessToken')  
    var key= process.env.VUE_APP_SECRET_KEY;
    var decrypted = CryptoJS.AES.decrypt(message, key);
    return decrypted.toString(CryptoJS.enc.Utf8)
}
