import axios from "@axios";
import api from "../../../services/api";
import qs from "qs";

export default {
  async GetCompanyReportData(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get("api/FullReport/GetCompanyReportData", {
          params: { name: payload.companyName,
          page:payload.page,
          pageSize:payload.pageSize },
          paramsSerializer: (params) => qs.stringify(params, { encode: false }),
        })
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  async UpdateCompanyComment(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .put("api/FullReport/UpdateCompanyComment", obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
};
