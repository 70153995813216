import {Decrypt} from './encrypt.js'

export default{
   GetUserData(state){
    return state.userData
   },
      isAuthenticated(state) {
        return !!state.userData.token;
      },
      didAutoLogout(state) {
        return state.didAutoLogout;
      },
      didLogout(state) {
        return state.logoutOverTabs;
      },
       
      ExtractAcessToken(){
        let accessToken = Decrypt()
        let jwtData = accessToken.split('.')[1]
        let decodedJwtJsonData = JSON.parse(window.atob(jwtData))
        return decodedJwtJsonData

      }
}