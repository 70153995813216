import api from '../../../services/api'

export default {
  async fetchBillOfLandingsGrid({ commit }, { pageSize, pageNumber }) {
    try {
      const response = await api.get(`api/billOfLandings/GetBillOfLandingsGrid?pageSize=${pageSize}&pageNumber=${pageNumber}`)
      commit('SET_BILLOFLANDINGS_GRID', {
        billOfLandings: response.data.result,
        pagesCount: response.data.pagesCount,
        totalCountData: response.data.totalCountData
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async openInvoiceDocument({ commit }) {
    try {
      const response = await api.get(`api/billOfLandings/GetBillOfLandingDoc/`, { responseType: 'blob' })
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank').focus()
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  //change status
  async changeStatus({ commit }, payload) {
    try {
      const response = await api.put(`api/billOfLandings/UpdateBillOfLandingStatus/`, payload)
      commit('UPDATE_BILLOFLANDING', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async fetchBillOfLandings({ commit }) {
    try {
      const response = await api.get('api/billOfLandings/GetBillOfLandings')
      commit('SET_BILLOFLANDINGS', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async fetchBillOfLanding({ commit }, id) {
    try {
      const response = await api.get(`api/billOfLandings/GetBillOfLanding/${id}`)
      commit('SET_BILLOFLANDING', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async createBillOfLanding({ commit }, billOfLanding) {
    try {
      const response = await api.post('api/billOfLandings/AddBillOfLanding', billOfLanding)
      response.data.companyName = billOfLanding.companyName
      commit('ADD_BILLOFLANDING', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateBillOfLanding({ commit }, billOfLanding) {
    try {
      const response = await api.put(`api/billOfLandings/UpdateBillOfLanding/`, billOfLanding)
      response.data.companyName = billOfLanding.companyName
      commit('UPDATE_BILLOFLANDING', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async deleteBillOfLanding({ commit }, id) {
    try {
      await api.delete(`api/billOfLandings/DeleteBillOfLanding/${id}`)
      commit('DELETE_BILLOFLANDING', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
