<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  data(){
    return {
      userInfo:null
    }
  },
  mounted(){
     if (localStorage.userInfo) {
      this.userInfo = localStorage.userInfo;
    }
    window.addEventListener("storage", this.onStorageUpdate);
    

  },
  methods:{
    onStorageUpdate(event) {
      if (event.key === "userInfo") {
        this.userInfo = event.newValue;
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  created() {
    this.$store.dispatch('tryLogin');
  },
  computed: {
     didAutoLogout() {
      return this.$store.getters.didAutoLogout
     },
     didLogout(){
      return this.$store.getters.didLogout
      
     },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      if (!this.$route.meta.hideNavbar) {
        return this.$store.state.appConfig.layout.type
      }
    },
  },
  watch:{
     didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
          this.$router.push({name:'login'}).catch(()=>{});
      }
    },
    didLogout(curValue, oldValue) {

      if (curValue && curValue !== oldValue) {

          this.$router.push({name:'login'}).catch(()=>{});
      }
    },
     
    
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style>
/* Global styles */
::-webkit-scrollbar {
  width: .7rem;
}

::-webkit-scrollbar-track {
  background: var(--bg-primary-500); /* Define this variable in your styles */
}

::-webkit-scrollbar-thumb {
  background: var(--bg-hover); /* Define this variable in your styles */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #a714ebe0 70%, #31295d61);
  box-shadow: inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5);
}
</style>