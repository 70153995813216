import axios from "@axios";
import api from "../../../services/api";

export default {
  async GetSafe(context) {
    return new Promise((resolve, reject) => {
      api
        .get("api/Safe/GetSafe")
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  async FilterSafeInputs(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(
          "api/Safe/GetSafeInputs",payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  async FilterSafeOutputs(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post(
          "api/Safe/GetSafeOutputs" ,payload
        )
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  async AddSafeInput(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("api/Safe/AddSafeInput", payload)
        .then(async function (response) {
          if (response.status == 204) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

  async AddSafeOutput(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("api/Safe/AddSafeOutput", payload)
        .then(async function (response) {
          if (response.status == 204) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  async RemoveSafeInput(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete("api/Safe/RemoveSafeInput/"+id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  async RemoveSafeOutput(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete("api/Safe/RemoveSafeOutput/"+id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },

};

