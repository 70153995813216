import company from '../company'

export default {
  admins: [],
  servicePlaces: [],
  reportItems: [],
  fullReportData: [],
  releasedShipments: [],
  fullReportColumns: []
}
