export default {
  //admins
  GetAdmins(state) {
    return state.admins
  },
  GetServicePlaces(state) {
    return state.servicePlaces
  },
  GetReportItems(state) {
    return state.reportItems
  },
  GetReportData(state) {
    return state.fullReportData
  },
  GetReleasedShipments(state) {
    return state.releasedShipments
  },
  GetRowReportData: (state) => (id) => {
    return state.fullReportData.find((i) => i.id == id)
  },
  GetFullReportColumns(state) {
    return state.fullReportColumns
  }
}
