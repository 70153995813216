import api from '../../../services/api'

export default {
  async fetchInvoices({ commit }) {
    try {
      const response = await api.get('/invoices')
      commit('SET_INVOICES', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  //fetch invoices to grid
  async fetchInvoicesGrid({ commit }, model) {
    try {
      const response = await api.post('api/invoices/GetInvoicesGrid', model)
      commit('SET_INVOICES_GRID', {
        invoices: response.data.result,
        pagesCount: response.data.pagesCount,
        totalCountData: response.data.totalCountData
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  //fetchInvoicesCompanyGrid
  async fetchInvoicesCompanyGrid({ commit }, model) {
    try {
      const response = await api.post('api/invoices/GetInvoicesCompanyGrid', model)
      commit('SET_INVOICES_GRID', {
        invoices: response.data.result,
        pagesCount: response.data.pagesCount,
        totalCountData: response.data.totalCountData
      })
      return response
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async fetchInvoice({ commit }, id) {
    try {
      const response = await api.get(`api/invoices/GetInvoiceById/${id}`)
      commit('SET_INVOICE', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async createInvoice({ commit }, invoice) {
    try {
      const response = await api.post('api/invoices/CreateInvoice', invoice)
      commit('ADD_INVOICE', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  // change invoice status
  async changeInvoiceStatus({ commit }, id) {
    try {
      await api.put(`api/invoices/ChangeInvoiceStatus/${id}`)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async changeInvoiceStatusToApproved({ commit }, id) {
    try {
      await api.put(`api/invoices/ChangeInvoiceStatusToApproved/${id}`)
      commit('UPDATE_INVOICE_BY_ID', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  //rejectInvoice
  async changeInvoiceStatusToReject({ commit }, id) {
    try {
      await api.put(`api/invoices/changeInvoiceStatusToReject/${id}`)
      commit('DELETE_INVOICE', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateInvoice({ commit }, invoice) {
    try {
      const response = await api.put(`api/invoices/updateInvoice/${invoice.id}`, invoice)
      commit('UPDATE_INVOICE', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async deleteInvoice({ commit }, id) {
    try {
      await api.delete(`api/invoices/DeleteInvoice/${id}`)
      commit('DELETE_INVOICE', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async sendInvoice({ commit }, id) {
    try {
      await api.post(`/invoices/${id}/send`)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  // open invoice document pdf
  async openInvoiceDocument({ commit }, id) {
    try {
      const response = await api.get(`api/invoices/GetInvoiceDoc/${id}`, { responseType: 'blob' })
      // open pdf in new tab in browser as blob
      const file = new Blob([response.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank').focus()
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  setInvoiceItems({ commit }, items) {
    commit('setInvoiceItems', items)
  },
  setNoticeItems({ commit }, items) {
    commit('setNoticeItems', items)
  },
  addInvoiceItem({ commit }, item) {
    commit('addInvoiceItem', item)
  },
  updateInvoiceItem({ commit }, updatedItem) {
    commit('updateInvoiceItem', updatedItem)
  },
  removeInvoiceItem({ commit }, itemId) {
    commit('removeInvoiceItem', itemId)
  },
  moveInvoiceItem({ commit }, payload) {
    commit('moveInvoiceItem', payload)
  },
  async fetchTaxes({ commit }) {
    try {
      const response = await api.get('api/taxes/getTaxes')
      commit('SET_TAXES', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  //fetch companies
  async fetchCompanies({ commit }) {
    try {
      const response = await api.get('api/company/getCompanies')
      commit('SET_COMPANIES', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
