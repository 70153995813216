import axios from '@axios'
import api from '../../../services/api'

export default {
  async GetCompanies(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Company/GetCompanies')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetCompany(context, companyId) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Company/GetCompany?id=' + companyId)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetMaxSort(context) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Company/GetMaxSort')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddCompany(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Company/CreateCompany', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  async UpdateCompany(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Company/UpdateCompany?id=' + obj.id, obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteCompany(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Company/DeleteCompany?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async FilterPayments(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Payments/GetPayments', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async FilterBills(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Bills/GetBills', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  async AddPayment(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Payments/CreatePayment', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteCompanyPayment(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Payments/DeletePayment?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddBill(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Bills/CreateBill', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteBill(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Bills/DeleteBill?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetReportItems(context) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetReportItems')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetCompanyReportItems(context, companyId) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetCompanyReportItems?id=' + companyId)
        .then(async function (response) {
          if (response.status == 200) {
            const data = response.data
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddReportItemToCompany(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/FullReport/AddReportItemToCompany', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}
