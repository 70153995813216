export default {
  getInvoices: (state) => state.invoices,
  getInvoice: (state) => state.invoice,

  pagesCount: (state) => state.pagesCount,
  totalCountData: (state) => state.totalCountData,

  getInvoiceItems: (state) => state.invoiceItems,

  getNoticeItems: (state) => state.noticeItems,

  getTaxes: (state) => state.taxes,
  getCompanies: (state) => state.companies
}
