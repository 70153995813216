import api from '../../../services/api'

export default {
  //fetchProductsGrid fetchProductsGrid({ pageSize: this.pageSize, pageNumber: this.pageNumber })
  async fetchProductsGrid({ commit }, { pageSize, pageNumber }) {
    try {
      const response = await api.get(`api/products/GetProductsGrid?pageSize=${pageSize}&pageNumber=${pageNumber}`)
      commit('SET_PRODUCTS_GRID', {
        products: response.data.result,
        pagesCount: response.data.pagesCount,
        totalCountData: response.data.totalCountData
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  async fetchProducts({ commit }) {
    try {
      const response = await api.get('api/products/GetProducts')
      commit('SET_PRODUCTS', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async fetchProduct({ commit }, id) {
    try {
      const response = await api.get(`api/products/GetProduct/${id}`)
      commit('SET_PRODUCT', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async createProduct({ commit }, product) {
    try {
      const response = await api.post('api/products/CreateProduct', product)
      commit('ADD_PRODUCT', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateProduct({ commit }, product) {
    try {
      const response = await api.put(`api/products/UpdateProduct/${product.id}`, product)
      commit('UPDATE_PRODUCT', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async deleteProduct({ commit }, id) {
    try {
      await api.delete(`api/products/DeleteProduct/${id}`)
      commit('DELETE_PRODUCT', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
