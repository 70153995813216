export default {
  SET_PRODUCTS_GRID(state, { products, pagesCount, totalCountData }) {
    state.products = products;
    state.pagesCount = pagesCount;
    state.totalCountData = totalCountData;
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_PRODUCT(state, product) {
    state.product = product
  },
  ADD_PRODUCT(state, product) {
    state.products.push(product)
  },
  UPDATE_PRODUCT(state, product) {
    const index = state.products.findIndex((i) => i.id === product.id)
    state.products.splice(index, 1, product)
  },
  DELETE_PRODUCT(state, id) {
    const index = state.products.findIndex((i) => i.id === id)
    state.products.splice(index, 1)
  }
}
