export default {
  SET_TAXES(state, taxes) {
    state.taxes = taxes
  },
  SET_TAX(state, tax) {
    state.tax = tax
  },
  ADD_TAX(state, tax) {
    state.taxes.push(tax)
  },
  UPDATE_TAX(state, tax) {
    const index = state.taxes.findIndex(item => item.id === tax.id)
    state.taxes.splice(index, 1, tax)
  },
  DELETE_TAX(state, id) {
    const index = state.taxes.findIndex(item => item.id === id)
    state.taxes.splice(index, 1)
  }
}
