export default{
    total:4550,
    income:[
        {
            id:1,
            price:20,
            details:"دفع 1 ",
            date: ((new Date("2022-11-21 11:13:00")).toISOString().split('T')[0]),
        }
    ],
    output:[
         {
                id:2,
                price:50,
                details:"سحب 1",
                date: ((new Date("2022-11-21 11:13:00")).toISOString().split('T')[0]),
         }
],
   
}