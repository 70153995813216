import axios from "@axios";
import api from "../../../services/api";
import router from "../../../router";
import { Encrypt, Decrypt } from "./encrypt.js";
let timer;
export default {
  async Auth(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post("api/Auth/Login", {
          userName: payload.userName,
          password: payload.password,
        })
        .then(async function (response) {
          if (response.status == 200) {
            //           const expiresIn = 3000000;
            // const expirationDate = new Date().getTime() + expiresIn;
            const data = response.data;
            let jwt = data.token;
            let jwtData = jwt.split(".")[1];
            let decodedJwtJsonData = JSON.parse(window.atob(jwtData));
            localStorage.setItem("userInfo", JSON.stringify(data));
            let jwtEncrypted = Encrypt(jwt);
            localStorage.setItem("accessToken", jwtEncrypted);
            localStorage.setItem("tokenExpiration", decodedJwtJsonData.exp);
            localStorage.setItem("autoLogout", false);
            const expiresIn =
              (decodedJwtJsonData.exp - decodedJwtJsonData.iat) * 100;

            timer = setTimeout(function () {
              context.dispatch("autoLogout");
            }, expiresIn);
            context.commit("SetUserData", data);
            resolve(response);
          }
        })
        .catch(function (error) {
          if (error.response) reject(error.response.data);
          else reject(error);
        });
    });
  },
  tryLogin(context) {
    // const userData = localStorage.getItem('userInfo');
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      let accessExtraction = context.getters.ExtractAcessToken;
      const expiresIn = accessExtraction.exp * 1000 - Date.now();
      if (expiresIn < 0) {
        context.dispatch("autoLogout");
      }

      timer = setTimeout(function () {
        context.dispatch("autoLogout");
      }, expiresIn);
    }
  },

  logout(context) {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("tokenExpiration");
    localStorage.removeItem("accessToken");
    clearTimeout(timer);
    context.commit("SetUserData", null);
    context.commit("SetLogoutOverTabs");
  },
  autoLogout(context) {
    context.dispatch("logout");
    //localStorage.setItem('autoLogout',true)
    context.commit("SetAutoLogout");
    // router.push({name:'login'})
  },
};
