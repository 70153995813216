import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import admins from './modules/admins'
import agents from './modules/agents'
import company from './modules/company'
import safe from './modules/safe'
import companyUser from './modules/company-user'
import agentUser from './modules/agent-user'
import auth from './modules/Auth'
import invoices from './modules/invoices'
import products from './modules/products'
import taxes from './modules/taxes'
import billOfLandings from './modules/billOfLandings'
import createMutationsSharer from 'vuex-shared-mutations'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    admins: admins,
    agents: agents,
    company: company,
    safe: safe,
    companyUser: companyUser,
    agentUser: agentUser,
    auth: auth,
    invoices: invoices,
    products: products,
    taxes: taxes,
    billOfLandings: billOfLandings
  },
  strict: process.env.DEV,
  plugins: [createMutationsSharer({ predicate: ['SetUserData', 'SetAutoLogout', 'SetLogoutOverTabs'] })]
  // ,createPersistedState({paths: ["auth"]})
})
