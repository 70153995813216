import axios from '@axios'
import api from '../../../services/api'

export default {
  async GetCoupons(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Coupons/GetCoupons', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetCoupon(context, id) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Coupons/GetCoupon?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async CreateCoupon(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Coupons/CreateCoupon', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateCoupon(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Coupons/UpdateCoupon', payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteCoupon(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Coupons/DeleteCoupon?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetExpenses(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Expenses/GetExpenses', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetExpense(context, id) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Expenses/GetExpense?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async CreateExpense(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Expenses/CreateExpense', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateExpense(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Expenses/UpdateExpense', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeletePayment(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Expenses/DeleteExpense?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  async UpdateAgentComment(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .put('api/FullReport/UpdateAgentComment', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetAgentCoupons(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Coupons/GetAgentCoupons', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetAgentExpenses(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Expenses/GetAgentExpenses', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }
}
