import axios from '@axios'
import api from '../../../services/api'

export default {
  async GetAdmins(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Admins/GetAdmins')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddAdmin(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Admins/CreateAdmin', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateAdmin(context, obj) {
    let id = obj.id
    let data = obj.data
    return new Promise((resolve, reject) => {
      api
        .put('api/Admins/UpdateAdmin?id=' + id, data)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteAdmin(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Admins/DeleteAdmin?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async ResetPassword(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Auth/ResetPassword', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  //service places
  async GetPlaces(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/Admins/GetServicePlaces')
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddPlace(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .post('api/Admins/CreateServicePlace', obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdatePlace(context, obj) {
    return new Promise((resolve, reject) => {
      api
        .put('api/Admins/UpdateServicePlace?id=' + obj.id, obj)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeletePlace(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/Admins/DeleteServicePlace?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  // GetFullReportColumns
  async GetFullReportColumns(context) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetFullReportColumns')
        .then(async function (response) {
          if (response.status == 200) {
            context.commit('SET_FULL_REPORT_COLUMNS', response.data)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetFullReportColumnsGrid(context) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetFullReportColumnsGrid')
        .then(async function (response) {
          if (response.status == 200) {
            // context.commit('SET_FULL_REPORT_COLUMNS', response.data)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  // save report columns
  async AddFullReportColumns(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/FullReport/AddFullReportColumns', payload)
        .then(async function (response) {
          if (response.status == 200) {
            context.commit('SET_FULL_REPORT_COLUMNS', payload)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  async AddReportItem(cotext, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/FullReport/CreateReportItem', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteReportItem(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/FullReport/DeleteReportItem?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetFullReportData(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetFullReportData?page=' + payload.page + '&pageSize=' + payload.pageSize)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async SearchFullReportData(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/FullReport/SearchFullReportData', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetReleasedShipments(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetReleasedShipments?page=' + payload.page + '&pageSize=' + payload.pageSize)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async GetRow(context, id) {
    return new Promise((resolve, reject) => {
      api
        .get('api/FullReport/GetRow?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateShipmentStatus(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .put('api/FullReport/UpdateShipmentStatus?id=' + payload.id + '&releaseDate=' + payload.releaseDate, {})
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async AddRow(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .post('api/FullReport/AddRow', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async UpdateRow(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .put('api/FullReport/UpdateRow', payload)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async DeleteRow(context, id) {
    return new Promise((resolve, reject) => {
      api
        .delete('api/FullReport/DeleteRow?id=' + id)
        .then(async function (response) {
          if (response.status == 200) {
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  async ExportReport(context, isReleased) {
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_LINK}/api/FullReport/ExportReport?isReleased=${isReleased}`)
      if (response.status === 200) {
        return response.data // Return the actual data here, not a stringified version
      } else {
        throw new Error('Response status is not OK')
      }
    } catch (error) {
      throw new Error('Error fetching report: ' + error.message)
    }
  }
}
