  export default{
  //safe
  GetSafeTotal(state){
    return state.total
  },
  GetSafeIncome(state){
    return state.income
  },
  GetSafeOutput(state){
    return state.output
  },
}