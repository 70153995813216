import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, isAuth } from '@/auth/utils'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'الصفحة الرئيسية',
        breadcrumb: [
          {
            text: 'الصفحة الرئيسية',
            active: true
          }
        ],
        roles: ['Admin', 'Company', 'User'],
        requiresAuth: true
      }
    },
    //admins
    {
      path: '/admins',
      name: 'admins',
      component: () => import('@/views/Admins/admins.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'المشرفين',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    // invoices
    {
      path: '/add-invoice',
      name: 'add-invoice',
      component: () => import('@/views/Invoices/add-invoice.vue'),
      meta: {
        pageTitle: 'اضافة فاتورة',
        breadcrumb: [
          {
            text: 'اضافة فاتورة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/edit-invoice/:id?',
      name: 'edit-invoice',
      props: true,
      component: () => import('@/views/Invoices/add-invoice.vue'),
      meta: {
        pageTitle: 'تعديل الفاتورة',
        breadcrumb: [
          {
            text: 'تعديل الفاتورة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/review-invoice/:id?',
      name: 'review-invoice',
      props: true,
      component: () => import('@/views/Invoices/add-invoice.vue'),
      meta: {
        pageTitle: 'مراجعة الفاتورة',
        breadcrumb: [
          {
            text: 'مراجعة الفاتورة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    //copy-invoice
    {
      path: '/copy-invoice/:id?',
      name: 'copy-invoice',
      props: true,
      component: () => import('@/views/Invoices/add-invoice.vue'),
      meta: {
        pageTitle: 'نسخ الفاتورة',
        breadcrumb: [
          {
            text: 'نسخ الفاتورة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/Invoices/invoices.vue'),
      meta: {
        pageTitle: 'الفواتير',
        breadcrumb: [
          {
            text: 'الفواتير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    //products
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/Products/Product.vue'),
      meta: {
        pageTitle: 'المنتجات',
        breadcrumb: [
          {
            text: 'المنتجات',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/billOfLandings',
      name: 'billOfLandings',
      component: () => import('@/views/BillOfLandings/BillOFLanding.vue'),
      meta: {
        pageTitle: 'بوالص',
        breadcrumb: [
          {
            text: 'بوالص',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    //taxes
    {
      path: '/taxes',
      name: 'taxes',
      component: () => import('@/views/Taxes/Taxes.vue'),
      meta: {
        pageTitle: 'الضرائب',
        breadcrumb: [
          {
            text: 'الضرائب',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    {
      path: '/admin-update/:id?',
      name: 'admin-update',
      props: true,
      component: () => import('@/views/Admins/admin-update.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'إضافة مشرف',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/reset-password/:id?',
      name: 'reset-password',
      props: true,
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'تعديل كلمة المرور',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/full-report',
      name: 'full-report',
      component: () => import('@/views/Admins/full-report.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'التقارير المجمعه',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/full-report-columns',
      name: 'full-report-columns',
      component: () => import('@/views/Admins/full-report-columns.vue'),
      meta: {
        pageTitle: 'اعدادات التقرير',
        breadcrumb: [
          {
            text: 'اعدادات التقرير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/report-items',
      name: 'report-items',
      props: true,
      component: () => import('@/views/Admins/report-items.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'بيانات التقرير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/released-shipments',
      name: 'released-shipments',
      component: () => import('@/views/Admins/released-shipments.vue'),
      meta: {
        pageTitle: 'المشرفين',
        breadcrumb: [
          {
            text: 'الشحنات المفرج عنها',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-report-item',
      name: 'add-report-item',
      component: () => import('@/views/Admins/add-report-item.vue'),
      meta: {
        pageTitle: 'التقارير المجمعه',
        breadcrumb: [
          {
            text: 'إضافة خانه للتقرير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-row/:id?',
      name: 'add-row',
      props: true,
      component: () => import('@/views/Admins/add-row.vue'),
      meta: {
        pageTitle: 'التقارير المجمعه',
        breadcrumb: [
          {
            text: 'إضافة داتا للتقرير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    //agents
    {
      path: '/agents/:id?',
      name: 'agents',
      props: true,
      component: () => import('@/views/Agents/agents.vue'),
      meta: {
        pageTitle: 'المندوبين',
        breadcrumb: [
          {
            text: 'المندوبين',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/agent-update/:id?',
      name: 'agent-update',
      props: true,
      component: () => import('@/views/Agents/agent-update.vue'),
      meta: {
        pageTitle: 'المندوبين',
        breadcrumb: [
          {
            text: 'بيانات مندوب',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/agent-details/:id?',
      name: 'agent-details',
      props: true,
      component: () => import('@/views/Agents/agent-details.vue'),
      meta: {
        pageTitle: 'المندوبين',
        breadcrumb: [
          {
            text: 'تفاصيل مدفوعات مندوب',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/service-places',
      name: 'service-places',
      props: true,
      component: () => import('@/views/Agents/service-places.vue'),
      meta: {
        pageTitle: 'المندوبين',
        breadcrumb: [
          {
            text: 'أماكن الخدمه',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-service-place/:id?',
      name: 'add-place',
      props: true,
      component: () => import('@/views/Agents/add-service-place.vue'),
      meta: {
        pageTitle: 'اماكن الخدمه',
        breadcrumb: [
          {
            text: 'إضافة مكان',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-cost/:id?',
      name: 'add-cost',
      props: true,
      component: () => import('@/views/Agents/add-cost.vue'),
      meta: {
        pageTitle: 'تفاصيل مندوب',
        breadcrumb: [
          {
            text: 'إضافة عهده',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    //companies
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/Company/companies.vue'),
      meta: {
        pageTitle: 'الشركات',
        breadcrumb: [
          {
            text: 'الشركات',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/company-update/:id?',
      name: 'company-update',
      props: true,
      component: () => import('@/views/Company/company-update.vue'),
      meta: {
        pageTitle: 'الشركات',
        breadcrumb: [
          {
            text: 'بيانات شركة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/comapny-details/:id?',
      name: 'company-details',
      props: true,
      component: () => import('@/views/Company/company-details.vue'),
      meta: {
        pageTitle: 'الشركات',
        breadcrumb: [
          {
            text: 'تفاصيل شركة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-to-account/:id?',
      name: 'add-to-account',
      props: true,
      component: () => import('@/views/Company/add-to-account.vue'),
      meta: {
        pageTitle: 'تفاصيل شركة',
        breadcrumb: [
          {
            text: 'إضافة دفع',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-bill/:id?',
      name: 'add-bill',
      props: true,
      component: () => import('@/views/Company/add-bill.vue'),
      meta: {
        pageTitle: 'تفاصيل شركة',
        breadcrumb: [
          {
            text: 'إضافة فاتورة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-company-report-details/:id?',
      name: 'add-company-report-details',
      props: true,
      component: () => import('@/views/Company/add-company-report-details.vue'),
      meta: {
        pageTitle: 'الشركات',
        breadcrumb: [
          {
            text: 'تفاصيل عناصر التقرير',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    //safe
    {
      path: '/safe',
      name: 'safe',
      component: () => import('@/views/Safe/safe.vue'),
      meta: {
        pageTitle: 'الخزنة',
        breadcrumb: [
          {
            text: 'تفاصيل الخزنة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    {
      path: '/add-income',
      name: 'add-income',
      component: () => import('@/views/Safe/add-income.vue'),
      meta: {
        pageTitle: 'الخزنة',
        breadcrumb: [
          {
            text: 'إضافة مبلغ الخزنة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },
    {
      path: '/add-out',
      name: 'add-out',
      component: () => import('@/views/Safe/add-out.vue'),
      meta: {
        pageTitle: 'الخزنة',
        breadcrumb: [
          {
            text: 'سحب مبلغ من الخزنة',
            active: true
          }
        ],
        roles: ['Admin'],
        requiresAuth: true
      }
    },

    //agent-user
    {
      path: '/agent-report',
      name: 'agent-report',
      component: () => import('@/views/Agent-User/report.vue'),
      meta: {
        pageTitle: 'التقارير',
        breadcrumb: [
          {
            text: 'مهماتي',
            active: true
          }
        ],
        roles: ['User'],
        requiresAuth: true
      }
    },
    {
      path: '/edit-agent-comment/:id?',
      name: 'edit-agent-comment',
      props: true,
      component: () => import('@/views/Agent-User/edit-agent-comment.vue'),
      meta: {
        pageTitle: 'التقارير',
        breadcrumb: [
          {
            text: 'تعديل كومنت على الشحنه',
            active: true
          }
        ],
        roles: ['User'],
        requiresAuth: true
      }
    },

    {
      path: '/coupons',
      name: 'coupons',
      component: () => import('@/views/Agent-User/coupons.vue'),
      meta: {
        pageTitle: 'القسايم',

        roles: ['User'],
        requiresAuth: true
      }
    },
    {
      path: '/add-coupon/:id?',
      name: 'add-coupon',
      props: true,
      component: () => import('@/views/Agent-User/add-coupon.vue'),
      meta: {
        pageTitle: 'القسايم',
        breadcrumb: [
          {
            text: 'إضافة قسيمة',
            active: true
          }
        ],
        roles: ['User'],
        requiresAuth: true
      }
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: () => import('@/views/Agent-User/expenses.vue'),
      meta: {
        pageTitle: 'المصاريف',

        roles: ['User'],
        requiresAuth: true
      }
    },
    {
      path: '/add-expense/:id?',
      name: 'add-expense',
      props: true,
      component: () => import('@/views/Agent-User/add-expense.vue'),
      meta: {
        pageTitle: 'المصاريف',
        breadcrumb: [
          {
            text: 'إضافة مصروف',
            active: true
          }
        ],
        roles: ['User'],
        requiresAuth: true
      }
    },
    //custody
    {
      path: '/custody',
      name: 'custody',
      component: () => import('@/views/Agents/custody.vue'),
      meta: {
        pageTitle: 'العهد',
        breadcrumb: [
          {
            text: 'العهد',
            active: true
          }
        ],

        roles: ['User'],
        requiresAuth: true
      }
    },

    //company-user
    {
      path: '/company-report',
      name: 'company-report',
      component: () => import('@/views/Company-User/report.vue'),
      meta: {
        pageTitle: 'التقارير',
        breadcrumb: [
          {
            text: 'مهماتي',
            active: true
          }
        ],
        roles: ['Company'],
        requiresAuth: true
      }
    },
    {
      path: '/edit-company-comment/:id?',
      name: 'edit-company-comment',
      props: true,
      component: () => import('@/views/Company-User/edit-company-comment.vue'),
      meta: {
        pageTitle: 'التقارير',
        breadcrumb: [
          {
            text: 'تعدبل كومنت على الشحنه',
            active: true
          }
        ],
        roles: ['Company'],
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      },
      roles: []
    },
    {
      path: '/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/NotAuthorized.vue'),
      meta: {
        //layout: 'full',
        roles: ['no']
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        roles: ['Admin', 'Company', 'User'],
        requiresAuth: true
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  let isAuthed
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  if (isLoggedIn) {
    isAuthed = isAuth(to.meta.roles)
  }

  if (
    !isLoggedIn &&
    to.path !== '/login'
    //  && !store.getters.isAuthenticated
    //to.meta.role
  ) {
    return next({
      name: 'login'
    }).catch((err) => {})
  } else if (isLoggedIn && to.path !== '/login' && !isAuthed && requiresAuth) {
    return next({ name: 'misc-not-authorized' })
  } else {
    next()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
