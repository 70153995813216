import api from '../../../services/api'

export default {
  async fetchTaxes({ commit }) {
    try {
      const response = await api.get('api/taxes/GetTaxes')
      commit('SET_TAXES', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async fetchTax({ commit }, id) {
    try {
      const response = await api.get(`api/taxes/GetTax/${id}`)
      commit('SET_TAX', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async createTax({ commit }, tax) {
    try {
      const response = await api.post('api/taxes/CreateTax', tax)
      commit('ADD_TAX', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async updateTax({ commit }, tax) {
    try {
      const response = await api.put(`api/taxes/UpdateTax/${tax.id}`, tax)
      commit('UPDATE_TAX', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  },
  async deleteTax({ commit }, id) {
    try {
      await api.delete(`api/taxes/DeleteTax/${id}`)
      commit('DELETE_TAX', id)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
